import { useState, useContext, useRef } from "react";
import { StyledDivForm } from "./style";
import { ContextHome } from "../../context/providerHome";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

interface FormProps {
  onSubmit: (data: FormData) => void;
  ref?: any;
  onClick?: () => void;
}

interface FormData {
  nome: string;
  email: string;
  telefone: string;
  tipo: "site institucional" | "landing page" | "e-commerce" | "seo";
  message: string;
}

const Form = ({ onSubmit, ref, onClick }: FormProps) => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState<
    "site institucional" | "landing page" | "e-commerce" | "seo"
  >("site institucional");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<Partial<FormData>>({});

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData: FormData = {
      nome,
      email,
      telefone,
      tipo,
      message,
    };

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const result = await emailjs.sendForm(
          "service_ntfwf6o",
          "template_byli2gs",
          event.currentTarget,
          "gI_HATZ0uvf-n9QUr"
        );
        console.log(result.text);
        setNome("");
        setEmail("");
        setTelefone("");
        setMessage("");
        setTipo("site institucional");
        toast.success("Mensagem enviada!");
      } catch (error: any) {
        console.error(error.response?.text || "Erro desconhecido");
        toast.error("Erro ao enviar mensagem");
      }
    } else {
      setErrors(validationErrors);
      toast.error("Mensagem não enviada");
    }
  };

  const blockedDomains = [
    "teste.com.br",
    "testes.com",
    "teste.com",
    "testes.com.br",
  ];

  const validateForm = (values: FormData) => {
    const errors: Partial<FormData> = {};

    if (!values.nome) {
      errors.nome = "Campo obrigatório";
    } else if (!/^[A-Za-z\s]+$/i.test(values.nome)) {
      errors.nome = "Somente letras são permitidas no campo de nome";
    }

    if (!values.email) {
      errors.email = "Campo obrigatório";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Endereço de e-mail inválido";
    } else {
      const domain = values.email.split("@")[1];
      if (
        blockedDomains.some((blockedDomain) => domain.includes(blockedDomain))
      ) {
        errors.email = "Esse domínio de e-mail não é permitido";
      } else if (errors.email === "Esse domínio de e-mail não é permitido") {
        delete errors.email;
      }
    }
    if (!values.telefone) {
      errors.telefone = "Campo obrigatório";
    } else if (!/^\d+$/.test(values.telefone)) {
      errors.telefone = "Somente números são permitidos no campo de telefone";
    }

    return errors;
  };

  const { setModalFaleConosco } = useContext(ContextHome);

  return (
    <StyledDivForm onSubmit={handleSubmit} onClick={onClick} ref={ref}>
      <h3>Contrate Agora</h3>
      <button className="btnform" onClick={() => setModalFaleConosco(false)}>
        <FaTimes />
      </button>
      <label>Nome</label>
      <input
        type="text"
        name="nome"
        value={nome}
        onChange={(event) => setNome(event.target.value)}
        placeholder="Digite seu nome"
      />
      {errors.nome && <div className="errorsdiv">{errors.nome}</div>}
      <label>Email</label>
      <input
        type="email"
        name="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Digite seu email"
      />
      {errors.email && <div className="errorsdiv">{errors.email}</div>}
      <label>Telefone</label>
      <input
        type="number"
        name="telefone"
        value={telefone}
        onChange={(event) => setTelefone(event.target.value)}
        placeholder="Digite seu telefone"
      />
      {errors.telefone && <div className="errorsdiv">{errors.telefone}</div>}
      <label>Tipo</label>
      <select
        name="tipo"
        value={tipo}
        onChange={(event) =>
          setTipo(
            event.target.value as
              | "site institucional"
              | "landing page"
              | "e-commerce"
              | "seo"
          )
        }
      >
        <option value="site institucional">Site Institucional</option>
        <option value="landing page">Landing Page</option>
        <option value="e-commerce">E-commerce</option>
        <option value="seo">SEO</option>
      </select>
      {errors.tipo && <div className="errorsdiv">{errors.tipo}</div>}
      <label>Mensagem</label>
      <textarea
        placeholder="Digite aqui sua mensagem"
        name="message"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <button type="submit">Enviar</button>
    </StyledDivForm>
  );
};

export default Form;
