
import { ButtonStyled } from './style'

interface iName {
  name: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ButtonComponent = ({name, onClick} : iName) => {

  return (
    <ButtonStyled onClick={onClick}>{name}</ButtonStyled>
  )
}