import { createContext, useState } from 'react';

interface iProviderValue {
  modalFaleConosco: boolean,
  setModalFaleConosco: React.Dispatch<React.SetStateAction<boolean>>
}

interface iPropsProvider {
  children: React.ReactNode;
}

export const ContextHome = createContext({} as iProviderValue);

export const ProviderHome = ({children}: iPropsProvider) => {

  const [modalFaleConosco, setModalFaleConosco] = useState(false)

  return (
    <ContextHome.Provider value={{
      modalFaleConosco,
      setModalFaleConosco
    }}>
      {children}
    </ContextHome.Provider>
  )
}