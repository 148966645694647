import React from "react";

const NotFoundComponent: React.FC = () => {
  return (
    <div>
      <h1>404 - Página não encontrada</h1>
      <p>A página que você está procurando não foi encontrada.</p>
    </div>
  );
};

export default NotFoundComponent;
