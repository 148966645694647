import styled from 'styled-components';

export const StyledCardsHome = styled.li`
  
  text-align: center;
  width: 300px;
  border: 2px solid #ff2978;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  height: 450px;

  &:hover{
    color: white;
    background-color: #ff2978;
  }

  > h3 {
    margin-top: 20px;
  }

  > p {
    margin-top: 50px;
    text-align: justify;
  }

  & > img {
    width: 64px;
    height: 64px;
    max-width: 65px;
    max-height: 65px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 5px;
    & > img {
      width: 45px;
      height: 45px;
      max-width: 50px;
      max-height: 50px;
    }
  }

`;
