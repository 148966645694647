import React, { useContext } from 'react';
import { StyledLandingPageComponent } from './style';
import { ContextHome } from '../../context/providerHome';
import { CardsHome } from '../../components/cardsHome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '../../styles/container';
import { useNavigate } from 'react-router-dom';
import AnimatedContent from '../../components/animate';

export const LandingPageComponent = () => {
  const { setModalFaleConosco } = useContext(ContextHome);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contato');
  };

  return (
    <AnimatedContent>
      <Container>
        <StyledLandingPageComponent>
          <div className="container">
            <div className="text">
              <p data-aos="fade-up" data-aos-duration="1000">
                Uma landing page é um site projetado para uma finalidade única,
                geralmente com o objetivo de gerar conversões, como vendas,
                cadastros ou downloads. Ter uma landing page bem projetada é uma
                estratégia crucial para empresas de todos os tamanhos que buscam
                aumentar suas conversões e gerar mais receita.
              </p>{' '}
              <br /> <br />
              <p data-aos="fade-up" data-aos-duration="1000">
                Ao contrário de uma página de site tradicional, uma landing page
                tem um foco específico e claro, com uma mensagem clara e direta.
                Ela pode ser usada para promover um produto ou serviço
                específico, capturar leads, fornecer informações importantes ou
                qualquer outra finalidade específica que a empresa deseje
                alcançar.
              </p>{' '}
              <br /> <br />
            </div>
            <div className="image">
              <img
                src={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1691545519/uploads/landingPage_vijeo0-removebg-preview_cvhzez.png'
                }
                alt="Imagem de Landing Page"
                loading="lazy"
                data-aos="flip-left"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div className="retangular-div">
            <p> Quero criar uma Landing Page!</p>
            <button onClick={handleClick}>Contrate Agora</button>
          </div>
          <div className="titulo-description">
            <h1 data-aos="fade-down" data-aos-duration="1000">
              Criação de Landing Page{' '}
            </h1>
            <span data-aos="fade-up" data-aos-duration="1000">
              Ao criar uma landing page, é importante ter em mente que ela deve
              ser projetada para atender a um público específico e fornecer
              informações relevantes para incentivar a ação desejada.
            </span>
            <span data-aos="fade-up" data-aos-duration="1000">
              Uma landing page também pode ser usada como uma ferramenta de
              teste, permitindo que a empresa teste diferentes abordagens e
              mensagens para determinar o que funciona melhor para seu
              público-alvo. Isso pode ajudar a identificar o que funciona e o
              que não funciona, permitindo que a empresa ajuste sua estratégia
              de marketing para obter melhores resultados.
            </span>
          </div>
          <section>
            <h2>Por que criar uma Landing Page?</h2>
            <div data-aos="fade-right" data-aos-duration="1000">
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/landing-page_zk5i6p.png'
                }
                title={'Aumento de conversões'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Maior chance de conversão de visitantes em clientes;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Foco em um único objetivo de conversão;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumento da relevância para o público-alvo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Otimização da página e na experiência do usuário;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Melhoria do índice de qualidade do Google Ads;
                      </span>
                    </li>
                  </ul>
                }
                route={'landingPage'}
              />

              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/landing-page_zk5i6p.png'
                }
                title={'Geração de leads qualificados'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Coletar informações importantes dos visitantes;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Segmentação mais eficaz do público-alvo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Redução do custo por lead;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumento da qualidade dos leads gerados;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Melhoria da experiência do usuário;</span>
                    </li>
                  </ul>
                }
                route={'landingPage'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/landing-page_zk5i6p.png'
                }
                title={'Maior visibilidade online'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aparecer nos resultados de busca do Google;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Otimizar a página para as palavras-chave;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumento da autoridade da sua marca;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumento do tráfego orgânico do site;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Possibilidade de share nas redes sociais;</span>
                    </li>
                  </ul>
                }
                route={'landingPage'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/landing-page_zk5i6p.png'
                }
                title={'Melhoria da imagem da marca'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior credibilidade para a marca;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior profissionalismo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Demonstra preocupação com a experiência do usuário;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumento da confiança dos clientes;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Possibilidade de diferenciação da concorrência;
                      </span>
                    </li>
                  </ul>
                }
                route={'landingPage'}
              />
            </div>
          </section>
        </StyledLandingPageComponent>
      </Container>
    </AnimatedContent>
  );
};
