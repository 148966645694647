import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { StyledWhatsapp } from "./style.";

const WhatsAppButton = () => {
  const openWhatsApp = () => {
    const phoneNumber = "+5511949482376";
    const message = "Olá, gostaria de obter mais informações sobre um projeto";
    const encodedMessage = encodeURIComponent(message);
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`,
      "_blank",
      "noopener"
    );
  };

  return (
    <StyledWhatsapp>
      <button
        className="fixed-button"
        onClick={openWhatsApp}
        aria-label="Botão do WhatsApp"
      >
        <FaWhatsapp size={24} color="#fff" />
      </button>
    </StyledWhatsapp>
  );
};

export default WhatsAppButton;
