export const data = [
    {
      question: "O que é um site institucional e por que minha empresa precisa de um?",
      answer:
        "Um site institucional é um site que apresenta informações sobre sua empresa, como sua história, serviços, produtos, missão e valores. É uma ferramenta de marketing importante para estabelecer a credibilidade de sua empresa online e se conectar com seu público-alvo.",
    },
    {
      question: "Qual a diferença entre um site institucional e uma landing page?",
      answer:
        "Uma landing page é uma página específica em seu site criada para uma finalidade específica, como para uma oferta de produto ou serviço, inscrição em um evento, download de um e-book, entre outros. Já o site institucional é um site mais completo, que apresenta informações sobre a empresa de forma mais ampla.",
    },
    {
      question: "Qual a diferença entre um site institucional e um e-commerce? ",
      answer:
        "Um site institucional apresenta informações sobre sua empresa, enquanto um e-commerce é uma loja virtual onde os clientes podem comprar seus produtos ou serviços online.",
    },
    {
      question: "Quanto tempo leva para criar um site?",
      answer:
        "O tempo de criação de um site pode variar dependendo da complexidade do projeto e dos recursos disponíveis. Normalmente, um site institucional pode levar de 4 a 8 semanas para ser concluído, enquanto um e-commerce pode levar de 8 a 12 semanas."
    },
    {
      question: "Quais são as informações e recursos que sua empresa precisa para criar meu site? ",
      answer:
        "Precisamos de informações como a história de sua empresa, seus produtos e serviços, público-alvo e objetivos para o site. Também precisamos de acesso a imagens e outros recursos de marca que você gostaria de incluir no site."
    },
    {
      question: "Como são definidos os prazos de entrega do projeto?",
      answer:
        "Os prazos de entrega do projeto são definidos com base nas necessidades do cliente e na complexidade do projeto. Trabalhamos em estreita colaboração com o cliente para definir um cronograma realista e garantir que o projeto seja concluído dentro do prazo."
    },
    {
      question: "Como é definido o valor de um projeto de criação de site?",
      answer:
        "O valor de um projeto de criação de site é definido com base nas necessidades do cliente, na complexidade do projeto e nos recursos necessários. Oferecemos orçamentos personalizados para cada projeto, com preços transparentes e sem surpresas.",
    },
    {
      question: "Como é feito o suporte após a criação do site?",
      answer:
        "Oferecemos suporte contínuo após a criação do site, incluindo atualizações e manutenção regular do site. Também estamos disponíveis para responder a quaisquer perguntas ou problemas que o cliente possa ter em relação ao site.",
    },
    {
      question: "Posso fazer alterações no site depois de finalizado o projeto?",
      answer:
      "Sim, é possível fazer alterações no site depois de finalizado o projeto. Oferecemos serviços de atualização e manutenção do seu site."
    }
];
  