import React, { useContext } from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledSitesComponent } from './style';
import { CardsHome } from '../../components/cardsHome';

import { ContextHome } from '../../context/providerHome';
import { Container } from '../../styles/container';
import { useNavigate } from 'react-router-dom';
import AnimatedContent from '../../components/animate';

export const DesenvolvimentoComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contato');
  };

  return (
    <AnimatedContent>
      <Container>
        <StyledSitesComponent>
          <div className="container">
            <div className="text">
              <p data-aos="fade-up" data-aos-duration="1000">
                A DreiCode oferece soluções completas para o sucesso online, com
                uma equipe especializada em desenvolvimento de websites.
                Cuidamos de todo o processo, desde o planejamento e até a
                implementação e manutenção contínua. Utilizamos tecnologias de
                ponta para criar websites dinâmicos, atraentes e responsivos.
              </p>
            </div>
            <div className="image">
              <img
                src={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1691546756/uploads/site_iukjgc-transformed_lkw88n.png'
                }
                alt="Imagem SEO Google"
                data-aos="flip-left"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div className="retangular-div">
            <p> Quero um site professional para minha empresa!</p>
            <button onClick={handleClick}>Contrate Agora</button>
          </div>
          <div className="titulo-description">
            <h1 data-aos="fade-down" data-aos-duration="1000">
              Criação de Sites{' '}
            </h1>
            <span data-aos="fade-up" data-aos-duration="1000">
              Um website é a entrada para o mundo digital de uma empresa,
              apresentando informações sobre sua identidade, produtos e valores.
              Cria credibilidade, reputação da empresa e permitindo a interação
              com os visitantes. Nossa empresa oferece serviços na criação de
              websites personalizados, combinando design atraente, usabilidade
              intuitiva e tecnologias modernas.
            </span>
            <br /> <br />
            <span data-aos="fade-up" data-aos-duration="1000">
              Desenvolvemos websites responsivos e otimizados para impulsionar a
              presença online e alcançar resultados concretos. Seja uma startup
              ou uma empresa consolidada, estamos prontos para criar o website
              ideal para o sucesso do seu negócio. Entre em contato conosco e
              transforme sua presença digital hoje mesmo.
            </span>
          </div>
          <section>
            <h2>Por que ser Digital?</h2>
            <div data-aos="fade-right" data-aos-duration="1000">
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247549/uploads/web_glctpf.png'
                }
                title={'Presença online'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior alcance e visibilidade;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Acesso 24/7 para seus clientes;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Possibilidade novos mercados;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Mostrar seus produtos e serviços;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior credibilidade e profissionalismo;</span>
                    </li>
                  </ul>
                }
                route={'desenvolvimento'}
              />

              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247549/uploads/web_glctpf.png'
                }
                title={'Marketing digital'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Marketing online;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Segmentação do público-alvo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Resultados em tempo real;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Otimização do investimento em marketing;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Reajuste rápido das campanhas;</span>
                    </li>
                  </ul>
                }
                route={'desenvolvimento'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247549/uploads/web_glctpf.png'
                }
                title={'Competitividade'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior competitividade no mercado;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Superar concorrentes não digitais;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Melhor comunicação com o público-alvo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Facilidade no suporte aos clientes;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Maior facilidade para atrair talentos e parceiros;
                      </span>
                    </li>
                  </ul>
                }
                route={'desenvolvimento'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247549/uploads/web_glctpf.png'
                }
                title={'Flexibilidade'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Adaptar-se rapidamente a mudanças no mercado;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Fácil atualizar informações sobre produtos e serviços;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior eficiência no atendimento ao cliente;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Possibilidade de criar novos canais de venda;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Oportunidade de testar novos modelos de negócio;
                      </span>
                    </li>
                  </ul>
                }
                route={'desenvolvimento'}
              />
            </div>
          </section>
        </StyledSitesComponent>
      </Container>
    </AnimatedContent>
  );
};
