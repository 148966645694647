import React, { useContext } from "react";
import { StyledSeoComponent } from "./style";
//import { ModalFaleConoscoComponent } from "../../components/modal";
import { ContextHome } from "../../context/providerHome";
import { CardsHome } from "../../components/cardsHome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "../../styles/container";
import { useNavigate } from "react-router-dom";
import AnimatedContent from "../../components/animate";

export const SeoComponent = () => {
  const { setModalFaleConosco } = useContext(ContextHome);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contato");
  };

  return (
    <AnimatedContent>
      <Container>
        <StyledSeoComponent>
          <div className="container">
            <div className="text">
              <p data-aos="fade-up" data-aos-duration="1000">
                Conte com nossos especialistas para projetos de SEO
                personalizados, criados especialmente para a sua empresa,
                oferecemos planejamento das ações de SEO, além validar se todas
                as estratégias alinhadas estejam em direção dos seus objetivos.
              </p>
            </div>
            <div className="image">
              <img
                src={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1691546137/uploads/seoPage_ynicdo.png"
                }
                alt="Imagem SEO Google"
                loading="lazy"
                data-aos="flip-left"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div className="retangular-div">
            <p> Quero implementar SEO do Google no meu Site!</p>
            <button onClick={handleClick}>Contrate Agora</button>
          </div>
          <div className="titulo-description">
            <h1 data-aos="fade-down" data-aos-duration="1000">
              Otimização para o SEO do Google{" "}
            </h1>
            <span data-aos="fade-up" data-aos-duration="1000">
              Na criação de sites, seguimos as melhores práticas de SEO para
              garantir que o seu site seja classificado nas primeiras posições
              nos resultados de pesquisa do Google. Nosso objetivo é otimizar
              completamente o seu site, permitindo que ele alcance excelentes
              resultados em um curto período de tempo.
            </span>
            <br /> <br />
            <span data-aos="fade-up" data-aos-duration="1000">
              O SEO do Google envolve práticas e técnicas para otimizar um site
              e melhorar sua visibilidade nos resultados de pesquisa orgânica.
              Isso inclui fatores como palavras-chave relevantes, conteúdo de
              qualidade, estrutura do site, velocidade de carregamento e uso de
              links.
            </span>
          </div>
          <section>
            <h2>Por que Garantir SEO do Google?</h2>
            <div data-aos="fade-right" data-aos-duration="1000">
              <CardsHome
                image={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1683247548/uploads/seo_qrzxhw.png"
                }
                title={"Importância do SEO"}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumenta a visibilidade e o tráfego do site;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Ajuda a alcançar novos clientes;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Melhora a experiência do usuário;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Aumenta a autoridade do site;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Gera resultados duradouros;</span>
                    </li>
                  </ul>
                }
                route={"seo"}
              />

              <CardsHome
                image={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1683247548/uploads/seo_qrzxhw.png"
                }
                title={"Planejamento de SEO"}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Análise de concorrência;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Definição de palavras-chave;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Otimização de conteúdo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Construção de links;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Monitoramento e análise de resultados;</span>
                    </li>
                  </ul>
                }
                route={"seo"}
              />
              <CardsHome
                image={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1683247548/uploads/seo_qrzxhw.png"
                }
                title={"Técnicas de SEO"}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Otimização de títulos e meta descrições;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Uso de palavras-chave em conteúdo;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Otimização de imagens;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Criação de conteúdo relevante;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Melhoria da velocidade do site;</span>
                    </li>
                  </ul>
                }
                route={"seo"}
              />
              <CardsHome
                image={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1683247548/uploads/seo_qrzxhw.png"
                }
                title={"Ferramentas de SEO"}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Google Lighthouse;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Google Search Console;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Google Meu Negócio;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>PageSpeed Insights;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Google Keyword Planner;</span>
                    </li>
                  </ul>
                }
                route={"seo"}
              />
            </div>
          </section>
        </StyledSeoComponent>
      </Container>
    </AnimatedContent>
  );
};
