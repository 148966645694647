import styled from 'styled-components';

export const StyledComponentHome = styled.div`
  padding-top: 80px;
  margin-top: 30px;
`;

export const StyledSectionCardsHome = styled.section`
  height: 1450px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2 {
    color: #180f4a;
    font-size: 36px;
  }

  span {
    color: #80008a;
    font-size: 36px;
  }

  @media (min-width: 720px) {
    height: 650px;
  }
   
`;

export const StyledSectionContrate = styled.div`
  width: 100%;
  background-image: linear-gradient(to right, #00b3c7, #0091e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px;

  div {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  form > .btnform {
    display: none;
  }

  @media (min-width: 720px) {
    flex-direction: row;

    div {
      width: 50%;
    }

    img {
      width: 50%;
    }
  }
`;

export const StyledSectionFaq = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 100%;

  & > h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: #180f4a;
  }
`;

export const Banner = styled.div`
  padding-top: 80px;
  background: url('https://res.cloudinary.com/dut76ufs0/image/upload/v1692041207/banner-bg_gswhad.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 700px;
  margin: auto;
  display: flex;

  & > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > div > div > img {
    width: 100%;
    min-height: 300px;
    height: auto;
  }

  & > div > div > div {
    width: 100%;
    padding: 20px 0;
  }

  & > div > div > div > h2 {
    text-align: center;
    font-size: 28px;
    font-weight: bolder;
    color: white;
  }

  & h3 {
    color: #800080;
    text-align: center;
    margin-top: 10px;
    font-size: 34px;
  }

  & h1 {
    color: #ffffff;
    margin-top: 20px;
    font-size: 45px;
  }

  & p {
    color: #ffffff;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  & > div > div > div > button {
    margin-top: 26px;
    left: 50%;
  }

  @media (min-width: 480px) {
    & > div > div > div > h2 {
      font-size: 32px;
    }

    & > h1 {
      font-size: 34px;
    }

    & > h3 {
      font-size: 30px;
    }

    & > p {
      font-size: 22px;
    }

    & > div > div {
      display: flex;
      flex-direction: row;
    }
    & > div > div > img {
      width: 50%;
    }

    & > div > div > div {
      width: 50%;
    }
  }

  @media (max-width: 479px) {
    button {
      margin-left: -80px;
    }
  }
`;
