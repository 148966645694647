import styled from 'styled-components';

export const StyledModalFaleConoscoBack = styled.div`
  
  background-color: transparent;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
}

@media (min-width: 480px) and  (max-width: 700px) {
  div > button {
    position: absolute;
    background-color: #ff2978 ;
    left: 96vw;
    bottom: 95vh;
    width: 25px;
    height: 25px;
    border-radius: 45%;
    color: white;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
  }
}

`;
