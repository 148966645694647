import styled from "styled-components";

export const StyledPrivacyPolicyComponent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px 60px;
  font-size: 16px;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    font-size: 14px;
  }

  h1 {
    margin-top: 100px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    color: blue;
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 16px;
  }
`;
