import { StyledPrivacyPolicyComponent } from "./style";

export const PrivacyPolicyComponent = () => {
  return (
    <StyledPrivacyPolicyComponent>
      <h1> POLÍTICA DE PRIVACIDADE</h1>
      <h2>Introdução</h2>
      <p>
        A DreiCode valoriza a privacidade de seus usuários e está comprometida
        em proteger suas informações pessoais. Esta Política de Privacidade
        explica como coletamos, usamos e divulgamos essas informações.
      </p>

      <h2>Informações coletadas</h2>
      <p>
        Ao utilizar o site da DreiCode, podemos coletar informações pessoais
        suas, como nome, endereço de e-mail, endereço físico, número de telefone
        e informações de pagamento, por exemplo, quando você se registra no
        site, faz uma compra ou se comunica com a empresa.
      </p>

      <h2>Uso de informações</h2>
      <p>
        As informações pessoais coletadas pela DreiCode são utilizadas para
        fornecer serviços e suporte ao cliente, processar pagamentos, enviar
        comunicações de marketing e melhorar o site da empresa. A DreiCode não
        compartilha informações pessoais dos usuários com terceiros, exceto
        quando exigido por lei.
      </p>

      <h2>Segurança de informações</h2>
      <p>
        A DreiCode toma medidas de segurança apropriadas para proteger as
        informações pessoais dos usuários contra acesso não autorizado,
        alteração, divulgação ou destruição. Isso inclui a utilização de
        criptografia de dados e padrões de segurança reconhecidos pela
        indústria.
      </p>

      <h2>Alterações na Política de Privacidade</h2>
      <p>
        A DreiCode reserva-se o direito de alterar esta Política de Privacidade
        a qualquer momento. Tais alterações entrarão em vigor imediatamente após
        a publicação no site da DreiCode.
      </p>

      <h2>Contato</h2>
      <p>
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato conosco pelo e-mail
        contato@dreicode.com.br
      </p>
    </StyledPrivacyPolicyComponent>
  );
};
