import styled from 'styled-components';

export const SectionContatoPageContato = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  h2 {
    margin-right: 150px;
    padding-top: 50px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #800080;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Roboto', Arial, sans-serif;
  }

  p {
    margin: 20px;
    font-size: 20px;
    line-height: 1.5;
    font-size: 20px;
    font-family: 'Roboto', Arial, sans-serif;
    text-align: justify;
  }

  & > div {
    width: 100%;
  }

  & > aside {
    width: 100%;
  }

  & > aside > form {
    width: 100%;
  }

  & > aside > form > .btnform {
    display: none;
  }

  @media (min-width: 720px) {
    flex-direction: row;

    & > div {
      width: 50%;
    }

    & > aside {
      width: 50%;
    }
  }

  @media (max-width: 468px) {
    h2 {
      text-align: justify;
      width: 90%;
      height: auto;
      font-size: 27px;
      margin-left: 20px;
      margin-top: 20px;
    }
  }
`;
