import { Header } from './components/header';
import { Container } from './styles/container';
import { FooterComponent } from './components/footer';
import { RoutesComponents } from './routes/routes';
import { ModalFaleConoscoComponent } from './components/modal';
import { ContextHome } from './context/providerHome';
import { useContext } from 'react';
import { ScrollToTopButton } from './components/ScrollToTopButton';
import WhatsAppButton from './components/whatsapp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  const { modalFaleConosco } = useContext(ContextHome);
  return (
    <div
      style={{
        background: 'radial-gradient(circle, #e7ddd3 0%, #d8ecfc 100%)',
      }}
    >
      <ToastContainer />
      <Container>
        ({modalFaleConosco ? <ModalFaleConoscoComponent /> : null})
        <Header />
      </Container>
      <RoutesComponents />
      <WhatsAppButton />
      <ScrollToTopButton />
      <FooterComponent />
    </div>
  );
};
