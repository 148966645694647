import { createGlobalStyle } from "styled-components";

export const StyledGlobal = createGlobalStyle`

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
  }

  button {
    cursor: pointer;
    background: transparent;
    box-shadow: none;
  }

`