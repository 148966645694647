import React from 'react';

import { CardsHome } from '../../components/cardsHome';

import { StyledEcommerceComponenet } from './style';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '../../styles/container';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '../../components/button';
import AnimatedContent from '../../components/animate';

export const EcommerceComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contato');
  };

  return (
    <AnimatedContent>
      <Container>
        <StyledEcommerceComponenet>
          <div className="container">
            <div className="text">
              <p data-aos="fade-up" data-aos-duration="1000">
                Você já pensou em expandir seu negócio para o mundo virtual? Com
                um e-commerce ou loja virtual, você pode aumentar sua presença
                na internet e alcançar um público muito maior! Além disso, com
                uma loja virtual você pode oferecer seus produtos 24 horas por
                dia, 7 dias por semana, sem limitações geográficas.
              </p>{' '}
            </div>
            <div className="image">
              <img
                src={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1691546503/uploads/ecommerce_qdyhlc-transformed_cgq3xy.png'
                }
                alt="Imagem ecommerce"
                loading="lazy"
                data-aos="flip-left"
                data-aos-duration="1000"
              />
            </div>
          </div>
          <div className="retangular-div">
            <p> Quero minha empresa Digital!</p>
            <ButtonComponent name={'Contrate Agora'} onClick={handleClick} />
          </div>
          <div className="titulo-description">
            <h1 data-aos="fade-down" data-aos-duration="1000">
              Criação de Loja digital ou E-commerce{' '}
            </h1>
            <span data-aos="fade-up" data-aos-duration="1000">
              Na DreiCode, somos especializados em criação de sites e oferecemos
              soluções personalizadas para cada cliente. Nossa equipe de
              profissionais está preparada para desenvolver uma loja virtual ou
              e-commerce que atenda às necessidades específicas da sua empresa,
              com layout atraente, funcionalidades avançadas e otimização para
              os motores de busca. Com isso, seu site estará posicionado nos
              primeiros resultados das buscas orgânicas e atrairá um público
              qualificado e engajado.
            </span>{' '}
            <br /> <br />
            <span data-aos="fade-up" data-aos-duration="1000">
              Não perca mais tempo e faça sua empresa dar o salto para o mundo
              digital! Entre em contato conosco agora mesmo e descubra como
              podemos ajudá-lo a criar uma loja virtual ou e-commerce.
            </span>
          </div>
          <section>
            <h2>Por que ter um E-commerce?</h2>
            <div data-aos="fade-right" data-aos-duration="1000">
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/e-commerce_zelzgz.png'
                }
                title={'Vantagens de ter um E-commerce'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior alcance de público;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Disponibilidade 24 horas;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Redução de custos com aluguel de loja física;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Possibilidade de vender em outros mercados;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Acesso a dados sobre o comportamento do cliente;
                      </span>
                    </li>
                  </ul>
                }
                route={'ecommerce'}
              />

              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/e-commerce_zelzgz.png'
                }
                title={'Aumento das vendas com um E-commerce'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Recomendações personalizadas para o cliente;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Dados do comportamento do cliente;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Facilidade para fazer promoções e descontos;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior visibilidade para produtos em destaque;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Cliente pode avaliar e comentar;</span>
                    </li>
                  </ul>
                }
                route={'ecommerce'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/e-commerce_zelzgz.png'
                }
                title={'Cliente pode comprar no E-commerce'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Maior comodidade para comprar de casa;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Possibilidade de fazer compras em qualquer horário;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Acesso a informações detalhadas sobre o produto;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Possibilidade de comparação entre produtos e preços;
                      </span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>
                        Facilidade para encontrar produtos específicos;
                      </span>
                    </li>
                  </ul>
                }
                route={'ecommerce'}
              />
              <CardsHome
                image={
                  'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/e-commerce_zelzgz.png'
                }
                title={'Redução de custos com um E-commerce'}
                desc={
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Redução de custos com aluguel de loja física;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Redução de custos com funcionários;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Redução de custos com estoque;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Gerenciar as vendas e o estoque;</span>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-hidden="true"
                      />
                      <span>Integrar com ferramentas de gestão;</span>
                    </li>
                  </ul>
                }
                route={'ecommerce'}
              />
            </div>
          </section>
        </StyledEcommerceComponenet>
      </Container>
    </AnimatedContent>
  );
};
