import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { StyledGlobal } from "./styles/globalStyle";
import { BrowserRouter } from "react-router-dom";
import { ProviderHome } from "./context/providerHome";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProviderHome>
        <StyledGlobal />
        <App />
      </ProviderHome>
    </BrowserRouter>
  </React.StrictMode>
);
