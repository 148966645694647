import { Link } from "react-router-dom";
import { HamburguerIcon, Menu, StyledComponentHeader, SubMenu } from "./style";
import { useState } from "react";
import DropdownMenu from "../dropdown/DropDown";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const subMenuHandleToggle = () => {
    console.log("oi");
    setSubMenuOpen(!subMenuOpen);
  };

  return (
    <StyledComponentHeader>
      <span>
        <Link to="/">
          <img
            src={
              "https://res.cloudinary.com/dut76ufs0/image/upload/v1706219392/uploads/logo_wttgyc.svg"
            }
            alt="logo"
            loading="lazy"
          />
        </Link>
        {/* <h2>DreiCode</h2> */}
      </span>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/sobreNos">Sobre Nós</Link>
        <DropdownMenu />
        <Link to="/contato">Contato</Link>
      </nav>
      <Menu open={open}>
        <li onClick={handleToggle}>
          <Link to="/">Home</Link>
        </li>
        <li onClick={handleToggle}>
          <Link to="/sobreNos">Sobre Nós</Link>
        </li>
        <button onClick={subMenuHandleToggle}>Serviços</button>
        {subMenuOpen && (
          <SubMenu>
            <ul>
              <li onClick={handleToggle}>
                <Link to="/desenvolvimento">Web Sites</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to="/landingPage">Landing Pages</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to="/ecommerce">Ecommerce</Link>
              </li>
              <li onClick={handleToggle}>
                <Link to="/seo">SEO do Google</Link>
              </li>
            </ul>
          </SubMenu>
        )}
        <li onClick={handleToggle}>
          <Link to="/contato">Contato</Link>
        </li>
      </Menu>
      <HamburguerIcon open={open} onClick={handleToggle}>
        <span></span>
        <span></span>
        <span></span>
      </HamburguerIcon>
    </StyledComponentHeader>
  );
};
