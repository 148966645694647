import React, { useState } from "react";
import { Wrapper, Question, Answer } from "./style";
import { BsChevronDown } from "react-icons/bs";

interface QA {
  question: string;
  answer: string;
}

interface Props {
  data: QA[];
}

export const Faq: React.FC<Props> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="1000">
      {data.map((item, index) => (
        <div key={index}>
          <Question
            className={activeIndex === index ? "active" : ""}
            onClick={() => handleClick(index)}
          >
            {item.question}
            <BsChevronDown />
          </Question>
          {activeIndex === index && <Answer>{item.answer}</Answer>}
        </div>
      ))}
    </Wrapper>
  );
};
