import { ButtonComponent } from "../../components/button";
import {
  StyledComponentHome,
  StyledSectionCardsHome,
  StyledSectionContrate,
  StyledSectionFaq,
} from "./style";

import Form from "../../components/form";
import { useRef } from "react";

import { data } from "../../components/FAQ/dataQuestion";
import { Faq } from "../../components/FAQ";
import { Banner } from "./style";
import { Container } from "../../styles/container";
import { TesteCarousel } from "../../components/carousel/carousel";
import AnimatedContent from "../../components/animate";

type ITypeFormRef = any;

export const HomeComponents = () => {
  interface FormData {
    nome: string;
    email: string;
    telefone: string;
    tipo: "site institucional" | "landing page" | "e-commerce" | "seo";
  }

  const formularioRef: ITypeFormRef = useRef(null);

  const handleSubmit = (data: FormData): void => {
    console.log(data);
  };

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <AnimatedContent>
        <Banner>
          <Container>
            <div>
              <div>
                <h1>Você encontrou a melhor opção de</h1>
                <h3>Agência Digital</h3>
                <p>
                  A DreiCode é a agência de destaque quando se trata de criação
                  de websites, landing pages, e-commerce e otimização SEO do
                  Google. Nossa equipe profissional está comprometida em
                  fornecer soluções digitais de alta qualidade, com designs
                  responsivos e usabilidade em smartphones e tablets.
                </p>
                <ButtonComponent
                  name={"Contrate Agora"}
                  onClick={handleClick}
                />
              </div>
              <img
                src={
                  "https://res.cloudinary.com/dut76ufs0/image/upload/v1691547285/uploads/img_f4n3ox-removebg_kvvwdm.png"
                }
                alt={"imagem digital"}
                data-aos="flip-left"
                loading="lazy"
                data-aos-duration="1000"
              />
            </div>
          </Container>
        </Banner>
      </AnimatedContent>
      <StyledComponentHome>
        <Container>
          <StyledSectionCardsHome>
            <h2 data-aos="fade-down" data-aos-duration="1000">
              O que podemos fazer pela sua <span> empresa? </span>
            </h2>
            <TesteCarousel />
          </StyledSectionCardsHome>
        </Container>
        <StyledSectionContrate ref={ref}>
          <img
            src={
              "https://res.cloudinary.com/dut76ufs0/image/upload/v1685660417/FundoContrate-removebg-preview_wwe1bh.png"
            }
            alt="imagem contrate agora"
            data-aos="fade-up"
            data-aos-duration="1000"
            loading="lazy"
          />
          <Form onSubmit={handleSubmit} ref={formularioRef} />
        </StyledSectionContrate>
        <StyledSectionFaq>
          <h2>Dúvidas Comuns</h2>
          <Faq data={data} />
        </StyledSectionFaq>
      </StyledComponentHome>
    </>
  );
};
