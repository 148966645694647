import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  & > div {
    margin: 0 auto;
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    & > div {
    margin: 0 auto;
    width: 100%;
  }

  }

`;

export const Question = styled.div`
  padding: 10px;
  font-size: 20px;
  font-weight: bolder;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #092d5d;
  width: 100%;
  min-height: 80px;

  @media screen and (max-width: 480px) {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #0091e1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  min-height: 80px;
  text-align: justify;
  }

  & > svg {
    transition: transform 0.3s;
    transform: rotate(0deg);
    color: #ff2978ff;
  }

  & > p {
    text-align: left;
  }

  &.active {
    color: #180f4a;
    font-size: 20px;
    font-weight: bolder;
    border: none;

    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Answer = styled.div`
  width: 100%;
  padding: 10px;
  color: black;
  font-size: 18px;
  line-height: 1.4;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #092d5d;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #2bdefd 0, #2bdefd 50%, #092d5d 90%, #092d5d);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 1s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;
