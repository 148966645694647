import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
  FaTiktok,
} from "react-icons/fa";
import { StyledFooterHome } from "./style";
import { Link } from "react-router-dom";

export const FooterComponent = () => {
  return (
    <StyledFooterHome>
      <h3>DreiCode</h3>
      <span>
        {" "}
        CNPJ : 46.085.003.0001-76
        <br />
      </span>
      <p>&copy; 2024 - Todos os direitos reservados.</p>
      <nav>
        <ul>
          <li>
            <Link to="/privacidade">Privacidade</Link>
          </li>
        </ul>
      </nav>
      <div>
        {/* <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebook color="#3b5998" />
        </a> */}
        <a
          href="https://www.instagram.com/dreicodetech/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <FaInstagram color="#c13584" />
        </a>
        {/* <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener 
        noreferrer"
          aria-label="Twitter"
        >
          <FaTwitter color="#1da1f2" />
        </a> */}
        {/* <a
          href="https://www.linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <FaLinkedin color="#0077b5" />
        </a> */}
        <a
          href="https://wa.me/+5511949482376"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="WhatsApp"
        >
          <FaWhatsapp color="#25d366" />
        </a>
        {/* <a
          href="https://www.tiktok.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Tiktok"
        >
          <FaTiktok color="#69c9d0" />
        </a> */}
      </div>
    </StyledFooterHome>
  );
};
