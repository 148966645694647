import { Route, Routes } from "react-router-dom";
import { HomeComponents } from "../pages/home";
import { SobreNosComponents } from "../pages/sobreNos";
import { LandingPageComponent } from "../pages/landingPage";
import { DesenvolvimentoComponent } from "../pages/desenvolvimento";
import { EcommerceComponent } from "../pages/e-commerce";
import { PrivacyPolicyComponent } from "../pages/privacidade";
import { SeoComponent } from "../pages/seo";
import { PageContato } from "../pages/contato/page";
import NotFoundComponent from "../pages/notFound";

export const RoutesComponents = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponents />} />
      <Route path="/sobreNos" element={<SobreNosComponents />} />
      <Route path="/landingPage" element={<LandingPageComponent />} />
      <Route path="/desenvolvimento" element={<DesenvolvimentoComponent />} />
      <Route path="/ecommerce" element={<EcommerceComponent />} />
      <Route path="/privacidade" element={<PrivacyPolicyComponent />} />
      <Route path="/contato" element={<PageContato />} />
      <Route path="/seo" element={<SeoComponent />} />
      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
};
