import styled from 'styled-components';

export const StyledComponentHeader = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  width: 100%;
  position: fixed;
  background-image: linear-gradient(to right, #00b3c7, #0091e1);
  top: 0;
  left: 0;
  z-index: 100;

  > span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    width: 100px;
    height: 100px;
  }

  img {
    margin-left: 30px;
    width: 200px;
    height: 200px;
  }

  span > h2 {
    font-family: 'Roboto', sans-serif;
    color: white;
  }

  nav {
    display: none;
  }

  @media (max-width: 480px) {
    img {
      width: 85px;
      height: 85px;
    }
  }

  @media (min-width: 700px) {
    nav {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    nav > a {
      color: white;
      text-decoration: none;
      font-size: 20px;
      padding: 20px 16px;
      background-color: transparent;
      border: none;
    }

    nav > a:hover {
      color: #ff2978;
      border-bottom: 4px solid #180f4a;
    }
  }
`;

export const HamburguerIcon: any = styled.div<{ open: boolean }>`
  margin-left: 20px;
  width: 30px;
  height: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  & span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: ${({ open }) => (open ? 'black' : '#000')};
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(48deg)' : 'none')};
      transform-origin: top left;
    }

    &:nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(-20px)' : 'none')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-48deg)' : 'none')};
      transform-origin: bottom left;
    }
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

export const Menu = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: fixed;
  padding: 40px 20px;
  gap: 20px;
  top: 0;
  left: ${({ open }) => (open ? '0' : '-100%')};
  width: 60%;
  height: 100%;
  background-color: #DAEAF7;
  transition: all 0.3s ease-in-out;

  & > li > a {
    font-size: 24px;
    text-decoration: none;
    color: #180f4a;
    margin-bottom: 20px;
  }

  & button {
    font-size: 24px;
    text-decoration: none;
    color: #180f4a;
    border: none;
    text-align: left;
  }

  a:hover {
    color: #ff2978;
    padding-top: 10px;
  }

  @media (min-width: 400px) {
    width: 50%;
  }

  @media (min-width: 600px) {
    width: 40%;
  }
`;

export const SubMenu = styled.div`
  & > ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > ul > li > a {
    font-size: 18px;
    text-decoration: none;
    color: #180f4a;
    margin-left: 10px;
  }
`;
