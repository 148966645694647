import React, { useContext, useState } from 'react';
import { Container } from '../../styles/container';
import { Circle, DivCircles, StyledComponentSobreNos } from './style';
import { ContextHome } from '../../context/providerHome';
import NotFoundComponent from '../notFound';
import AnimatedContent from '../../components/animate';

export const SobreNosComponents = () => {
  const [error, setError] = useState(false);
  const { setModalFaleConosco } = useContext(ContextHome);

  localStorage.setItem(
    'image',
    'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247546/uploads/about-dec_zwvxs2.png'
  );

  const imageAboutUs = localStorage.getItem('image') + '';

  // Função para lidar com erro
  const handleError = () => {
    setError(true);
  };

  if (error) {
    return <NotFoundComponent />;
  }
  return (
    <AnimatedContent>
      <Container>
        <StyledComponentSobreNos>
          <img
            src={imageAboutUs}
            alt="imagem sobre nós"
            loading="lazy"
            // data-aos="flip-left"
            // data-aos-duration="1000"
          />
          <div>
            <h2>Sobre Nós</h2>
            <h1>
              Quem é a agência{' '}
              <span data-aos="fade-up" data-aos-duration="1000">
                DreiCode ?
              </span>
            </h1>
            <p>
              A DreiCode é uma agência fundada em 2023, localizada em São Paulo.
              Seu nome é derivado do alemão, onde "Drei" significa "três", e
              "Code" representa desenvolvedores. A empresa surgiu da colaboração
              de três desenvolvedores com a missão de fazer a diferença no mundo
              digital.
            </p>{' '}
            <p>
              A equipe da DreiCode é composta por profissionais experientes e
              especializados na criação de diversos tipos de projetos, incluindo
              websites, landing pages, E-commerce e otimização para mecanismos
              de busca (SEO) do Google. Além disso, eles se dedicam à melhoria
              de websites existentes, visando que sejam totalmente responsivos e
              ofereçam uma experiência de uso perfeita em dispositivos móveis,
              como smartphones e tablets.
            </p>
            <DivCircles>
              <Circle
                progress={100}
                color="#FF2978"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2>100%</h2>
                <p>Profissional</p>
              </Circle>
              <Circle
                progress={100}
                color="blue"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <h2>100%</h2>
                <p>SEO Otimizada</p>
              </Circle>
              <Circle
                progress={100}
                color="orange"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2>100%</h2>
                <p>Personalizada</p>
              </Circle>
              <Circle
                progress={100}
                color="purple"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <h2>100%</h2>
                <p>Responsivo</p>
              </Circle>
              <span>
                <button onClick={() => setModalFaleConosco(true)}>
                  Contrate Agora
                </button>
              </span>
            </DivCircles>
          </div>
        </StyledComponentSobreNos>
      </Container>
    </AnimatedContent>
  );
};
