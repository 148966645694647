import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { StyledCard, StyledDiv, NewCard } from "./style";
import { Link } from "react-router-dom";

import { TbSeo } from "react-icons/tb";
import { FaShoppingCart } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { RiPagesLine } from "react-icons/ri";

export const TesteCarousel = () => {
  return (
    <StyledDiv>
      {/* <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        keyboard={{ enabled: true }}
        modules={[EffectCoverflow, Pagination, A11y]}
      >
        <SwiperSlide>
          <StyledCard>
            <img
              src={
                'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247549/uploads/web_glctpf.png'
              }
              alt="icon desenvolvimento web"
            />
            <h2 data-aos="fade-right" data-aos-duration="1000">
              Desenvolvimento de Sites
            </h2>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, oferecemos uma solução completa para o seu sucesso
              online. Nossa equipe especializada em desenvolvimento de websites
              está pronta para transformar sua visão em realidade. Desde o
              planejamento e design inicial até a implementação e manutenção
              contínua, cuidamos de todos os aspectos do processo com o uso de
              tecnologias de ponta, criamos websites dinâmicos, atraentes e
              totalmente responsivos.{' '}
            </p>
            <button>
              {' '}
              <Link to={'/desenvolvimento'}>Saiba mais</Link>
            </button>
          </StyledCard>
        </SwiperSlide>
        <SwiperSlide>
          <StyledCard>
            <img
              src={
                'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/landing-page_zk5i6p.png'
              }
              alt="icon landing page"
            />
            <h2 data-aos="fade-right" data-aos-duration="1000">
              Criação de Landing Page
            </h2>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, criamos landing pages personalizadas para impulsionar
              o sucesso da sua estratégia de marketing digital. Nossas páginas
              são projetadas para aumentar as taxas de conversão, atraindo e
              persuadindo os visitantes. Com um design atraente e conteúdo
              direcionado, focamos em levar os visitantes a realizar a ação
              desejada, seja capturar leads, vender produtos ou promover ofertas
              especiais transformando clientes em potencial em clientes reais.
            </p>
            <button>
              {' '}
              <Link to={'/landingPage'}>Saiba mais</Link>
            </button>
          </StyledCard>
        </SwiperSlide>
        <SwiperSlide>
          <StyledCard>
            <img
              src={
                'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247547/uploads/e-commerce_zelzgz.png'
              }
              alt="icon e-commerce"
            />
            <h2 data-aos="fade-right" data-aos-duration="1000">
              Desenvolvimento E-Commerce
            </h2>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, fornecemos soluções completas para o sucesso do seu
              e-commerce permitindo que você venda seus produtos ou serviços
              diretamente online. Do design responsivo à funcionalidade
              avançada, garantimos que sua loja virtual seja otimizada para
              aumentar as vendas e impulsionar o crescimento do seu negócio.
              Juntos, transformaremos suas ideias em uma loja virtual de
              sucesso, permitindo que você alcance novos clientes e expanda sua
              presença no mercado digital.
            </p>
            <button>
              {' '}
              <Link to={'/ecommerce'}>Saiba mais</Link>
            </button>
          </StyledCard>
        </SwiperSlide>
        <SwiperSlide>
          <StyledCard>
            <img
              src={
                'https://res.cloudinary.com/dut76ufs0/image/upload/v1683247548/uploads/seo_qrzxhw.png'
              }
              alt="icon SEO"
            />
            <h2 data-aos="fade-right" data-aos-duration="1000">
              SEO do Google
            </h2>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode o time de SEO utilizará sua experiência e conhecimento
              para impulsionar a visibilidade da sua empresa nos mecanismos de
              busca do Google. Identificaremos as palavras-chave relevantes para
              o seu setor e otimizaremos seu conteúdo para alcançar melhores
              classificações nos resultados de pesquisa. Aumente sua
              visibilidade online e alcance o público certo com os serviços de
              SEO da DreiCode. Estamos prontos para impulsionar sua presença
              digital e levar seu negócio ao próximo nível.
            </p>
            <button>
              {' '}
              <Link to={'/seo'}>Saiba mais</Link>
            </button>
          </StyledCard>
        </SwiperSlide>
      </Swiper> */}

      <NewCard>
        <figure>
          <TbWorldWww
            size={90} // Ajuste o tamanho do ícone conforme necessário
            color="#000000" // Ajuste a cor do ícone conforme necessário
            aria-label="E-Commerce Icon" // Texto alternativo para acessibilidade
          />
        </figure>
        <div>
          <h2 data-aos="fade-right" data-aos-duration="1000">
            Desenvolvimento de Sites
          </h2>
          <Link to={"/desenvolvimento"}>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, proporcionamos soluções completas para o sucesso
              online, transformando sua visão em websites modernos e
              responsivos. Nossa equipe cuida de cada etapa, desde o
              planejamento até a manutenção contínua.
            </p>
          </Link>
          {/* <button>
              {' '}
              <Link to={'/desenvolvimento'}>Saiba mais</Link>
            </button> */}
        </div>
      </NewCard>

      <NewCard>
        <figure>
          <RiPagesLine
            size={90} // Ajuste o tamanho do ícone conforme necessário
            color="#000000" // Ajuste a cor do ícone conforme necessário
            aria-label="Landing Icon" // Texto alternativo para acessibilidade
          />
        </figure>

        <div>
          <h2 data-aos="fade-right" data-aos-duration="1000">
            Criação de Landing Page
          </h2>

          <Link to={"/landingPage"}>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, desenvolvemos landing pages personalizadas para
              maximizar sua estratégia de marketing digital. Nossas páginas
              atraentes e focadas em conversão transformam visitantes em
              clientes reais.
            </p>
          </Link>
          {/* <button>
                {' '}
                <Link to={'/landingPage'}>Saiba mais</Link>
              </button> */}
        </div>
      </NewCard>

      <NewCard>
        <figure>
          <FaShoppingCart
            size={90} // Ajuste o tamanho do ícone conforme necessário
            color="black" // Ajuste a cor do ícone conforme necessário
            aria-label="E-Commerce Icon" // Texto alternativo para acessibilidade
          />
        </figure>

        <div>
          <h2 data-aos="fade-right" data-aos-duration="1000">
            Desenvolvimento E-Commerce
          </h2>
          <Link to={"/ecommerce"}>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, oferecemos soluções completas para transformar seu
              e-commerce em um sucesso. Design responsivo com funcionalidades
              avançadas, criamos uma loja virtual otimizada para aumentar suas
              vendas e expandir sua presença no mundo digital.
            </p>
          </Link>
          {/* <button>
              {' '}
              <Link to={'/landingPage'}>Saiba mais</Link>
            </button> */}
        </div>
      </NewCard>

      <NewCard>
        <figure>
          <TbSeo
            size={100} // Ajuste o tamanho do ícone conforme necessário
            color="#000000" // Ajuste a cor do ícone conforme necessário
            aria-label="SEO icon" // Texto alternativo para acessibilidade
          />
        </figure>
        <div>
          <h2 data-aos="fade-right" data-aos-duration="1000">
            SEO do Google
          </h2>

          <Link to={"/seo"}>
            <p data-aos="fade-down" data-aos-duration="1000">
              Na DreiCode, nosso time utiliza sua expertise em SEO para aumentar
              a visibilidade da sua empresa no Google. Com análise de
              palavras-chave ajudamos você a alcançar posições de destaque
              conectando sua marca ao público certo e crescendo digitalmente.
            </p>
          </Link>
          {/* <button>
                {' '}
                <Link to={'/seo'}>Saiba mais</Link>
              </button> */}
        </div>
      </NewCard>
    </StyledDiv>
  );
};
