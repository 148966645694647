import { useState } from 'react';
import { Container } from '../../styles/container';
import { SectionContatoPageContato } from './style';
import Form from '../../components/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import AnimatedContent from '../../components/animate';

interface FormData {
  nome: string;
  email: string;
  telefone: string;
  tipo: 'site institucional' | 'landing page' | 'e-commerce' | 'seo';
}

export const PageContato = () => {
  const [transform, setTransform] = useState(
    'perspective(1000px) rotateX(0deg) rotateY(0deg)'
  );

  const handleSubmit = (data: FormData): void => {
    console.log(data);
  };

  return (
    <>
      <AnimatedContent>
        <Container>
          <SectionContatoPageContato>
            <div data-aos="fade-down" data-aos-duration="1000">
              <h2>Venha fazer parte da DreiCode?</h2>
              <p>
                Caso deseje apenas conversar para obter informações adicionais
                sobre nossos serviços, estamos igualmente disponíveis para um
                bate-papo. <FontAwesomeIcon icon={faComments} />
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> contato@dreicode.com.br
              </p>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Rua pires de campos,
                268 - Mooca - São Paulo
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} /> Ligar (11) 94948-2376 ou
                (Whatsapp)
              </p>
            </div>
            <aside data-aos="fade-right" data-aos-duration="1000">
              <Form onSubmit={handleSubmit} />
            </aside>
          </SectionContatoPageContato>
        </Container>
      </AnimatedContent>
    </>
  );
};
