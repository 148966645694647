import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  DropdownItem,
} from "./styled";

const DropdownMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef: any = useRef<number | null>(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    clearTimeout(closeTimeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <DropdownContainer>
      <DropdownButton
        onClick={handleToggleDropdown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span>Serviços</span>
      </DropdownButton>
      <DropdownContent
        isOpen={isOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <DropdownItem>
          {" "}
          <Link to="/desenvolvimento">Web Sites</Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/landingPage">Landing Page</Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/ecommerce">E-commerce</Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/seo">SEO do Google</Link>
        </DropdownItem>
      </DropdownContent>
    </DropdownContainer>
  );
};

export default DropdownMenu;
