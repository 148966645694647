import styled from 'styled-components';


export const StyledScrollToTopButton = styled.button`

  position: fixed;
  right: 80px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  background-color: #180f4a;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 50px;
  z-index: 1;

`;
