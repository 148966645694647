import styled from 'styled-components';

export const StyledComponentSobreNos = styled.div`
  padding-top: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #180f4a;
  width: 100%;

  > img {
    width: 100%;
    height: 100%;
    animation: flipLeft 0.5s forwards;
  }

  @keyframes flipLeft {
    from {
      transform: perspective(2500px) rotateY(-100deg);
    }
    to {
      transform: perspective(2500px) rotateY(0);
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  > div > h2 {
    color: purple;
    margin-bottom: 10px;
  }

  > div > h1 > span {
    color: purple;
  }

  > div > p {
    margin-top: 30px;
    padding: auto;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 40px;
  }

  span {
    position: relative;
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  span > button {
    color: #fff;
    height: 60px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #180f4a;
    border: none;
    border-radius: 50px;
    padding: 15px 30px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  button:hover {
    background-color: #f0134d;
  }

  @media (min-width: 835px) {
    > div {
      width: 45%;
    }
    img {
      width: 45%;
    }
  }
  @media (min-width: 768px) {
    > div {
      width: 50%;
    }
    img {
      width: 45%;
    }
  }
  @media (max-width: 700px) {
    > div > p {
      padding: 10px;
    }
  }

  @media (max-width: 480px) {
    button {
      margin: 0 auto;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      background-color: #180f4a;
      border: none;
      border-radius: 50px;
      padding: 15px 30px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      margin-left: -30px;
    }

    button:hover {
      background-color: #f0134d;
    }

    h2 {
      text-align: center;
    }

    > div > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

interface CircleProps {
  color: string;
  progress?: number;
}

export const DivCircles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Circle = styled.div<CircleProps>`
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    background: conic-gradient(
      ${(props) => props.color || 'purple'} ${(props) => props.progress || 0}%,
      transparent ${(props) => props.progress || 0}%
    );
    background-clip: content-box;
    -webkit-mask: radial-gradient(circle at center, transparent 60%, black 70%);
    mask: radial-gradient(circle at center, transparent 60%, black 70%);
  }
`;

export const StyledSectionContrate = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
