import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: transparent;;
    border: none;
  }
`;

export const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #0091e1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%;
  left: 0;
  width: 200px;
`;

export const DropdownItem = styled.a`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  border-bottom: 1px solid white;

  & > a {
    font-weight: bold;
    color: white;
  }

  &:hover {
    background-color: #DCE8F0;
    border-bottom: 2px solid #ff2978;

    & > a {
      color: #0091e1;
    }
  }
`;
