import styled from 'styled-components';

export const StyledSeoComponent = styled.div`
  padding-top: 80px;
  margin-top: 30px;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    .text {
      width: 50%;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5;
      color: #180f4a;
      text-indent: 0.5em;
      text-align: justify;
      margin-bottom: 1.5rem;
    }

    .image {
      margin-top: 50px;
      width: 750px;
      height: 600px;

      img {
        width: 90%;
        height: auto;
      }
    }
  }

  .retangular-div {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    margin-top: 50px;
    background-image: linear-gradient(to right, #00b0c9, #00a2d5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    color: white;

    p {
      font-size: 24px;
      font-weight: bold;
    }

    button {
      font-size: 18px;
      background-color: #180f4a;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      z-index: 100;
    }

    button:hover {
      background-color: #ff2978;
    }
  }

  .titulo-description > h1 {
    padding-top: 350px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #800080;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Roboto', Arial, sans-serif;
  }

  .titulo-description > span {
    margin-top: 10px;
    line-height: 1.5;
    font-size: 20px;
    font-family: 'Roboto', Arial, sans-serif;
    text-align: justify;
  }

  section > h2 {
    margin-top: 50px;
    text-align: center;
    color: #180f4a;
    font-size: 35px;
    font-weight: bold;
  }

  section > div {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul li {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    text-align: left;
  }

  ul > li > span {
    margin-left: 10px;
  }

  @media screen and (min-width: 768px) and (max-width: 779px) {
    .container {
      flex-direction: column;
      margin-bottom: 30px;

      .text {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
      }

      .image {
        width: 100%;
        margin-top: 90px;
        margin-bottom: 30px;
        order: -1;
      }
    }

    .retangular-div {
      height: 200px;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;

      p {
        font-size: 20px;
      }

      button {
        font-size: 16px;
        padding: 8px 16px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    section > h2 {
      font-size: 20px;
    }

    ul li span {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      flex-direction: column;
      margin-bottom: 30px;

      .text {
        margin-top: -200px;
        width: 100%;
        color: #333;
        font-size: 1.4rem;
        font-weight: 200;
        line-height: 1.4;
        text-indent: 0.1em;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
      }

      .image {
        width: 100%;
        margin-top: 5px;
        order: -1;
      }
    }

    .retangular-div {
      height: 200px;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;

      p {
        font-size: 1.25rem;
        }
      button {
        font-size: 20px;
        margin-top: 40px;
        margin-left: 10px;
        padding: 10px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .titulo-description > h1 {
      text-align: center;
      margin-bottom: 20px;
      color: #800080;
      font-size: 40px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
    }

    .titulo-description > p {
      margin-top: 10px;
      line-height: 1.5;
      font-size: 16px;
      font-family: 'Roboto',  sans-serif;
    }

    section > h2 {
      font-size: 1.75rem;
    }

    ul li span {
      font-size: 16px;
      margin-left: 10px;
    }
    .titulo-description > span {
      color: #333 !important;
        font-size: 1.3rem !important;
        font-family: 'Roboto', sans-serif;
     }
  }
`;
