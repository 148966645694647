import styled from 'styled-components';

export const ButtonStyled = styled.button`
  background-color: #180f4a;
  padding: 10px 25px;
  color: white;
  border-radius: 5px;
  border: 1px solid #180f4a;
  position: relative;

  &:hover {
    border: none;
    border: 1px solid #ff2978;
    background-color: #ff2978;
  }
`;
