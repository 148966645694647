import { StyledModalFaleConoscoBack } from "./style";
import Form from "../form";

export const ModalFaleConoscoComponent = (): JSX.Element => {
  const handleSubmit = () => {
    console.log("oi");
  };

  return (
    <StyledModalFaleConoscoBack>
      <div>
        <Form onSubmit={handleSubmit} />
      </div>
    </StyledModalFaleConoscoBack>
  );
};
