import styled from 'styled-components';

export const NewCard = styled.li`

  width: 100%; 
  height: 300px; 
  margin-top: 20px;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;

   &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  & > figure {
    height: 40%; 
    width: 30%;
    margin: 0 auto;
    z-index: 2; 
  }

  & > figure > img {
    height: 100%;
    width: 100% ;
  }

  & > div {
    z-index: 2;
    color: white;
    height: 70%;
    width: 100%;
    padding: 5px;
  }

  & > div > a > p {
    color: #000000; 
    font-weight: bolder;
    font-size: 0.9rem !important;
    line-height: 1.6;
    margin-top: 10px;
  }

  & > div > h2 {
    font-size: 1.25rem;;
    color: #180F4A;
  }

  &::before {
    content: '';
    position: absolute;
    width: 200%; 
    height: 200%; 
    background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
    animation: rotBGimg 7s linear infinite;
    transition: all 0.2s linear;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    background: #E3E1DE;
    inset: 5px;
    border-radius: 15px;
    z-index: 1;
  }

  @keyframes rotBGimg {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }



  //********** layout desktop ****************** //
  @media (min-width: 768px) {
  width: 40%;
  height: 220px;
  background-color: #f5f5f5;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  margin-top: 40px;
  flex-direction: row;

  & > figure {
    height: 70%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > figure > img {
    height: 100%;
    width: 100%;
  }

  & > div{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 60%;
    height: 100%;
    text-align: left
  }

  & > div > a > p {
    font-size: 11px;
    margin-top: 10px;
  }

  & > div > h3 {
    font-size: 15px
  }
  }

 
  // ********************** layout para desktops com resolução alta ********************** //
  @media (min-width: 1360px) {
    height: 190px;

    & > figure {
      height: 80%;
      width: 20%;
    }

    & > div > h3 {
      font-size: 18px
    }

    & > div > a > p {
      font-size: 16px;
      margin-top: 10px;
    }
  }
`

export const StyledDiv = styled.div`
  width: 100%;
  height: 1000px;
  padding: 10px;

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
  }


  /* justify-content: center;
  align-items: center;

  .swiper {
    padding: 25px;
    width: 100%;
    position: relative;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .swiper-pagination-bullet-active {
    background-color: #180f4a;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 2px solid #180f4a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #180f4a;
    transition: background-color 0.3s, color 0.3s;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: #180f4a;
    color: white;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  @media (min-width: 720px) {
    .swiper-button-prev {
      position: none;
      left: 0;
      display: flex;
      justify-content: left;
    }

    .swiper-button-next {
      position: none;
      right: 0;
      display: flex;
      justify-content: right;
    }
  } */
`;

export const StyledCard = styled.li`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 450px;
  height: auto;
  margin-left: 20px;
  min-width: 300px;
  max-width: 450px;
  gap: 10px;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: #f5f5f5;
  border-radius: 20px;
  border: 2px solid #009dd7;
  box-shadow: 3px -1px 17px 0px #009dd7;
  -webkit-box-shadow: 3px -1px 17px 0px #009dd7;
  -moz-box-shadow: 3px -1px 17px 0px #009dd7;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  }

  & > img {
    width: 60px;
    height: 60px;
  }

  & > h2 {
    font-size: 24px;
    color: #180f4a;
  }

  & > p {
    color: 00000;
    font-size: 17px;
    font-weight: 400;
    text-align: justify;
  }

  & > button {
    background-color: #180f4a;
    border-color: #180f4a;
    text-decoration: none;
    appearance: none;
    border: none;
    padding: 15px 10px;
    border-radius: 25px;
  }

  & > button > a {
    color: white;
  }

  & > button:hover {
    background-color: #ff2978;
  }
`;
