import styled from 'styled-components';

export const StyledDivForm = styled.form`
  background-color: #180f4a;
  display: flex;
  text-align: start;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
  min-height: 600px;
  color: white;
  position: relative;

  > label {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  > h3{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: white;
  }

  .btnform {
    top: -18px;
    right: 10px;
    position: absolute;
    width: 39px;
    height: 39px;
    background-color: white;
    color: black;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid white;
    border-radius: 50px;
  }

  input{
    padding-left: 10px; 
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 5px;
  }

  select{
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 5px;
  }

  button{
    margin-top: 30px;
    background-color: white;
    padding:  10px 25px;
    color: black;
    border-radius: 5px;
    border: 1px solid #180f4a;
  }
  
  textarea {
  padding: 10px;
}

  button:hover{
    border: none;
    border: 1px solid #ff2978;
    background-color: #ff2978;
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    height: 60px;
    border-radius: 5px;
  }

  & > .errorsdiv {
    color: red;
  }

  @media(min-width: 700px){
    width: 45%;
    
  }

`;

  