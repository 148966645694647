import styled from 'styled-components';

export const StyledFooterHome = styled.footer`
  width: 100%;
  margin-top: 100px;
  background-image: linear-gradient(180deg, #180F4A, #090422, black);
  padding: 40px 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  span {
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    margin: 20px 0;
  }

  li {
    margin-left: 13px;
    text-align: center;
  }

  a {
    margin-right: 15px;
    color: white;
  }

  svg {
    align-items: center;
    transition: all 0.2s ease-in-out;
    justify-content: center;
    width: 30px;
    height: 30px;
  }

  svg:hover {
    transform: translateY(-5px);
    
  }
`;
