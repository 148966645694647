import { StyledCardsHome } from "./style";
import { useNavigate } from "react-router-dom";
import React from "react";

interface iParamCarHome {
  image: any;
  title: string;
  desc: any;
  route: string;
}

export const CardsHome = ({ image, title, desc, route }: iParamCarHome) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>, route: string) => {
    console.log(event.target);
    console.log(event.currentTarget);
    navigate(`/${route}`);
  };

  return (
    <StyledCardsHome onClick={(e) => handleClick(e, route)}>
      <img src={image} alt={"imagem digital"} loading="lazy" />
      <h3>{title}</h3>
      <p>{desc}</p>
    </StyledCardsHome>
  );
};
